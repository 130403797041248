import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './QuestionsTable.css';

import * as XLSX from 'xlsx';

import { check_svg } from '../static/svg';
import img_download from '../static/download.png'
import img_refresh from '../static/refresh.png'
import img_clear from '../static/close.png'
import img_logout from '../static/logout.png'

import { fetchQuestions } from './api';


let s = 0

const QuestionsTable = ({ setIsAuthenticated }) => {
    const [questions, setQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'f', direction: 'ascending' });

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            console.log('stickyBlock', window.scrollY);

            if (window.scrollY >= 200) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Очистка после размонтирования компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const reorderQuestions = (questions) => {
        const questionMap = new Map();
        questions.forEach(q => questionMap.set(q.id, q));

        const orderedQuestions = [];
        const movedQuestions = [];

        questions.forEach(q => {
            if (q.second !== 0) {
                movedQuestions.push(q);
            } else {
                orderedQuestions.push(q);
            }
        });

        movedQuestions.forEach(mq => {
            const targetIndex = orderedQuestions.findIndex(q => q.id === mq.second);
            if (targetIndex !== -1) {
                orderedQuestions.splice(targetIndex + 1, 0, mq);
            } else {
                orderedQuestions.push(mq);
            }
        });

        console.log('orderedQuestions', orderedQuestions)

        return orderedQuestions;
    };


    useEffect(() => {
        // Fetch questions from the backend
        // axios.post(`${API_URL}/api/questions`, {
        //     j: 90
        // })
        //     .then(response => {
        //         console.log('questions', response.data)
        //         const reorderedQuestions = reorderQuestions(response.data);
        //         setQuestions(reorderedQuestions);
        //         setFilteredQuestions(reorderedQuestions);
        //     })
        //     .catch(error => {
        //         console.error('There was an error fetching the questions!', error);
        //     });
        const getQuestions = async () => {
            try {
                const data = await fetchQuestions();
                const reorderedQuestions = reorderQuestions(data);
                setQuestions(reorderedQuestions);

                const sortedQuestions = [...reorderedQuestions];
                if (sortConfig.key !== 'f') {
                    sortedQuestions.sort((a, b) => {
                        if (a[sortConfig.key] < b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key] > b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                        return 0;
                    });
                }

                setFilteredQuestions(sortedQuestions);
            } catch (error) {
                console.error(error);
            }
        };

        getQuestions();
    }, []);

    useEffect(() => {
        // Filter questions by date range
        if (startDate && endDate) {
            setFilteredQuestions(questions.filter(question => {
                const questionDate = new Date(question.datetime);
                // console.log(startDate, questionDate, endDate)

                const newDate = new Date(endDate);
                newDate.setDate(newDate.getDate() + 1);
                newDate.setSeconds(newDate.getSeconds() - 1);
                return questionDate >= startDate && questionDate <= newDate;
            }));
        } else {
            setFilteredQuestions(questions);
        }
    }, [startDate, endDate, questions]);

    // const requestSort = key => {
    //     console.log(s)


    //     let direction = 'ascending';
    //     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    //         direction = 'descending';
    //     }
    //     console.log('requestSort', { key, direction })
    //     setSortConfig({ key, direction });
    //     if (s === 0) {
    //         s = 1
    //         direction = 'descending';
    //         // requestSort(key)
    //         setSortConfig({ key, direction });
    //     }
    // };

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        let sortedQuestions = [...questions];

        if (key === 'second') {
            console.log('Sorting by second');

            // Сортируем по полю 'second'
            sortedQuestions.sort((a, b) => {
                if (a.second < b.second) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a.second > b.second) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });

            const questionMap = new Map();
            sortedQuestions.forEach(q => questionMap.set(q.id, q));

            const reorderedQuestions = [];
            const addedQuestions = new Set();

            sortedQuestions.forEach(q => {
                if (!addedQuestions.has(q.id)) {
                    reorderedQuestions.push(q);
                    addedQuestions.add(q.id);
                }
                if (q.second && !addedQuestions.has(q.second)) {
                    const secondQuestion = questionMap.get(q.second);
                    if (secondQuestion) {
                        // Вставляем перед текущим вопросом
                        reorderedQuestions.push(secondQuestion);
                        addedQuestions.add(secondQuestion.id);
                    }
                }

            });

            // Обновляем состояние для отрисовки
            setFilteredQuestions(reorderedQuestions);
        } else {
            // Общая сортировка для других полей
            sortedQuestions.sort((a, b) => {
                if (a[key] < b[key]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });

            // Обновляем состояние для отрисовки
            setFilteredQuestions(sortedQuestions);
        }
    };



    const resetFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredQuestions(questions);
    };

    // const sortedQuestions = [...filteredQuestions].sort((a, b) => {
    //     if (a[sortConfig.key] < b[sortConfig.key]) {
    //         return sortConfig.direction === 'ascending' ? -1 : 1;
    //     }
    //     if (a[sortConfig.key] > b[sortConfig.key]) {
    //         return sortConfig.direction === 'ascending' ? 1 : -1;
    //     }
    //     return 0;
    // });


    // setSortConfig({ key: 'id', direction: 'ascending' });

    // const downloadExcel = () => {
    //     const worksheet = XLSX.utils.json_to_sheet(sortedQuestions);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");
    //     XLSX.writeFile(workbook, "questions.xlsx");
    // };
    const downloadExcel = () => {
        // Определите порядок и названия столбиков
        // const modifiedQuestions = sortedQuestions.map(question => ({
        const modifiedQuestions = filteredQuestions.map(question => ({
            Номер: question.id,
            Вопрос: question.text,
            '1 уровень': question.first ? 'V' : '',
            'Вопрос 1': question.q1 ? 'V' : '',
            'Вопрос 2': question.q2 ? 'V' : '',
            'Вопрос 3': question.q3 ? 'V' : '',
            'Нет нужного': question.notneed ? 'V' : '',
            'Задать другой': question.another ? 'V' : '',
            '2 уровень': question.second ? question.second : '',
            'Дата': new Date(question.datetime).toLocaleDateString('ru-RU'),
        }));

        // Создайте worksheet и workbook
        const worksheet = XLSX.utils.json_to_sheet(modifiedQuestions);

        // Определите ширину столбцов
        worksheet['!cols'] = [
            { wch: 10 }, // Номер
            { wch: 30 }, // Вопрос
            { wch: 15 }, // 1 уровень
            { wch: 15 }, // Вопрос 1
            { wch: 15 }, // Вопрос 2
            { wch: 15 }, // Вопрос 3
            { wch: 15 }, // Нет нужного
            { wch: 15 }, // Задать другой
            { wch: 15 }, // 2 уровень
            { wch: 15 }, // Дата
        ];



        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");

        // Запишите файл
        XLSX.writeFile(workbook, "questions.xlsx");
    };

    const refreshQuestions = async () => {
        try {
            const data = await fetchQuestions();
            setQuestions(data);
            setFilteredQuestions(data);
        } catch (error) {
            console.error('Error refreshing questions:', error);
        }
    };

    const unLogin = () => {
        localStorage.removeItem('username'); // Удаляем логин из localStorage
        localStorage.removeItem('password'); // Удаляем пароль из localStorage
        // Вы также можете вызвать функцию, которая перенаправляет пользователя на страницу входа
        console.log('User logged out');
        setIsAuthenticated(false)
    };



    return (
        <div>
            <div className="date-filters">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    placeholderText="Start Date"
                    showMonthDropdown
                    showYearDropdown
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => {
                        setEndDate(date);
                    }}
                    placeholderText="End Date"
                    showMonthDropdown
                    showYearDropdown
                />
                <div style={{ width: '40px' }}>
                    {startDate && endDate && <div onClick={resetFilter}><img style={{ width: '30px', height: '30px', cursor: 'pointer' }} src={img_clear} alt='' /></div>}
                </div>
                {/* <button style={{ marginLeft: '100px' }} onClick={downloadExcel}>Скачать</button> */}
                {/* <button style={{ marginLeft: '100px' }} onClick={unLogin}>Выйти</button> */}
                <div onClick={unLogin}><img style={{ width: '30px', height: '30px', cursor: 'pointer' }} src={img_logout} alt='' /></div>
                <div onClick={downloadExcel}><img style={{ width: '30px', height: '30px', cursor: 'pointer' }} src={img_download} alt='' /></div>
                <div onClick={refreshQuestions}><img style={{ width: '30px', height: '30px', cursor: 'pointer' }} src={img_refresh} alt='' /></div>
            </div>
            {/* <div class="container">
                <div class="col">Content 1</div>
                <div class="col">Content 2</div>
                <div class="col">Content 3</div>
                <div class="col">Content 4</div>
                <div class="col">Content 5</div>
                <div class="col">Content 6</div>
                <div class="col">Content 7</div>
                <div class="col">Content 8</div>
                <div class="col">Content 9</div>
            </div> */}
            {/* <div style={{height: '100px'}}></div> */}
            <table id="block5" className={isSticky ? 'sticky' : 'absolutly'}>
                <tbody>
                    <tr style={{ backgroundColor: '#219ebc', color: '#fff', cursor: 'pointer'}}>
                        <td title='Порядковый номер вопроса' onClick={() => requestSort('id')}>Номер</td>
                        <td title='Текст вопроса от пользователя' onClick={() => requestSort('text')}>Вопрос</td>
                        <td title='Первый вопрос, который пользователь написал в бота' onClick={() => requestSort('first')}>1 уровень</td>
                        <td title='Нажатие на кнопку "Вопрос 1": первый вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q1')}>Вопрос 1</td>
                        <td title='Нажатие на кнопку "Вопрос 2": второй вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q2')}>Вопрос 2</td>
                        <td title='Нажатие на кнопку "Вопрос 3": третий вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q3')}>Вопрос 3</td>
                        <td title='Нажатие кнопки "Нет нужно": вторая попытка пользователя задать первоначальный вопрос переформулировав его.' onClick={() => requestSort('notneed')}>Нет нужного</td>
                        <td title='Нажатие кнопки "Задать другой": переход пользователя к другому вопросу.' onClick={() => requestSort('anotder')}>Задать другой</td>
                        <td title='Отправка пользователем переформулиррованного вопроса после нажатия кнопки "Нет нужного"' onClick={() => requestSort('second')}>2 уровень</td>
                        <td title='Дата поступления сообщения' onClick={() => requestSort('datetime')}>Дата</td>
                        <td>ОС</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        {/* <th title='Порядковый номер вопроса' onClick={() => requestSort('id')}>Номер</th>
                        <th title='Текст вопроса от пользователя' onClick={() => requestSort('text')}>Вопрос</th>
                        <th title='Первый вопрос, который пользователь написал в бота' onClick={() => requestSort('first')}>1 уровень</th>
                        <th title='Нажатие на кнопку "Вопрос 1": первый вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q1')}>Вопрос 1</th>
                        <th title='Нажатие на кнопку "Вопрос 2": второй вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q2')}>Вопрос 2</th>
                        <th title='Нажатие на кнопку "Вопрос 3": третий вариант предложенного вопроса из нашей базы пользователю.' onClick={() => requestSort('q3')}>Вопрос 3</th>
                        <th title='Нажатие кнопки "Нет нужно": вторая попытка пользователя задать первоначальный вопрос переформулировав его.' onClick={() => requestSort('notneed')}>Нет нужного</th>
                        <th title='Нажатие кнопки "Задать другой": переход пользователя к другому вопросу.' onClick={() => requestSort('another')}>Задать другой</th>
                        <th title='Отправка пользователем переформулиррованного вопроса после нажатия кнопки "Нет нужного"' onClick={() => requestSort('second')}>2 уровень</th> */}
                    </tr>
                </thead>
                <tbody>
                    {filteredQuestions.map(question => (
                        <tr key={question.id}
                            style={{ height: '10vh' }}
                            className={
                                (question.second || (question.first && question.notneed)) ?
                                    'highlighted-row ' + (question.second ? 'h-30' : '') :
                                    ''
                            }
                        >
                            <td>{question.id}</td>
                            <td>{question.text}</td>
                            <td>{question.first ? check_svg : ''}</td>
                            <td>{question.q1 ? check_svg : ''}</td>
                            <td>{question.q2 ? check_svg : ''}</td>
                            <td>{question.q3 ? check_svg : ''}</td>
                            <td>{question.notneed ? check_svg : ''}</td>
                            <td>{question.another ? check_svg : ''}</td>
                            <td>{question.second ? question.second : ''}</td>
                            <td style={{fontSize: 12}}>{question.datetime ? new Date(question.datetime).toLocaleDateString('ru-RU') : ''}</td>
                            <td>{question.feedback ? question.feedback : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QuestionsTable;
